import React from "react";
import Title from "./Title";
// import learningPacks from "../constants/learningPacks"
import { graphql, Link, useStaticQuery } from "gatsby";
const LearningPacks = () => {
  const query = graphql`
    {
      allStrapiLearningpacks(sort: { fields: id }) {
        nodes {
          id
          Subject
          Description
          Thumbnail {
            publicURL
          }
          Price
        }
      }
    }
  `;
  const data = useStaticQuery(query);
  const {
    allStrapiLearningpacks: { nodes: learningPacks },
  } = data;
  return (
    <section className="section bg-grey">
      <Title title="Resources" />
      <div className="section-center services-center">
        {learningPacks.map((learningPack, index) => {
          const { id, Subject, Thumbnail } = learningPack;
          return (
            <Link to="/resources" state={{ subjectIndex: index }}>
              <article key={id} className="service">
                <h4>{Subject}</h4>
                <div className="underline"></div>
                <p>test</p>
                <img src={Thumbnail.publicURL} width="300px" height="200px" alt={Subject} />
              </article>
            </Link>
          );
        })}
      </div>
    </section>
  );
};

export default LearningPacks;
